import type { JsonLD } from 'nuxt-jsonld/dist/types/index.d';

export type FaqElement = { answer: string, question: string };
export type ExpansionPanelElement = {
  title: string, text: string
}

const LendiCorpFAQ: FaqElement[] = [
  {
    question: 'Co to jest program Lendi Corp?  ',
    answer: 'Program Lendi Corp to propozycja dla pracodawców, którzy szukają ciekawych i użytecznych benefitów pracowniczych. W ramach programu możemy objąć bezpłatną opieką eksperta finansowego wszystkich pracowników i wesprzeć ich merytorycznie i praktycznie w sprawach, związanych z ich planami kredytowymi, finansowymi i ubezpieczeniowymi.  ',
  },
  {
    question: 'Kto może wziąć udział w programie? ',
    answer: 'W programie Lendi Corp może wziąć udział każdy pracodawca, będący osobą prawną.',
  },
  {
    question: 'Co otrzymuję w zamian za uczestnictwo w programie? ',
    answer: 'Twoi pracownicy otrzymują opiekę eksperta finansowego oraz dostęp do porównania najlepszych ofert na rynku, badania zdolności kredytowej i analizy osobistych zobowiązań finansowych.',
  },
  {
    question: 'Czy za wsparcie eksperta w ramach programu ponoszę koszty?',
    answer: 'Nie. Współpraca z ekspertem Lendi jest bezpłatna.',
  },
];

const LendiRaspFAQ: FaqElement[] = [
  {
    question: 'Jakie korzyści daje opieka eksperta finansowego?',
    answer: `<p class="mb-3">W ostatnich latach obserwujemy rosnące zapotrzebowanie rynku konsumenckiego na profesjonalne usługi finansowe. Wiąże się to z zawirowaniami na rynku nieruchomościowym i kredytowym, związane z gorszą koniunkturą gospodarczą. Po serii podwyżek stóp procentowych i nałożeniu na banki przez KNF konieczności stosowania dodatkowych obostrzeń przy wyliczaniu m.in. zdolności kredytowej, profesjonalna obsługa eksperta finansowego staje się niezbędna w procesie starania się o kredyt.</p>
            <p>Pomoc eksperta nie ogranicza się jednak wyłącznie do wsparcia w rozpoczynającym się procesie kredytowym. W zakres usług eksperta wchodzi również m.in. analiza dotychczasowych umów kredytowych, pomoc w optymalizowaniu zobowiązania, niosąca ze sobą faktyczną zmianę na korzyść w comiesięcznych wydatkach. Ekspert z sukcesem może przeprowadzić restrukturyzację naszego zadłużenia, np. wspierając przy refinansowaniu kredytu, przejściu z dotychczasowego oprocentowania zmiennego, a więc podatnego na wszystkie zmiany wynikające z decyzji NBP, na oprocentowanie okresowo stałe, gwarantujące kilka lat przewidywalności dla portfela. Ekspert pomoże też w konsolidacji zobowiązań i wypracowaniu optymalnej dla możliwości finansowych miesięcznej raty. Konsultacja z ekspertem to także okazja do audytu finansów i sprawdzenia jakie zobowiązanie będzie bezpieczne i najlepiej dopasowane do sytuacji zawodowo-prywatnej.</p>`,
  },
  {
    question: 'Czy opieka eksperta finansowego coś mnie kosztuje?',
    answer: 'Opieka eksperta finansowego nie rodzi żadnych kosztów po Twojej stronie. Wszystkie koszty z bieżącą konsultacją pokrywa Twój poprzedni pracodawca.',
  },
  {
    question: 'Czy mogę się ubiegać o kredyt w trakcie wypowiedzenia?',
    answer: `<p class="mb-3">Wszystko zależy od formy zatrudnienia, jaka wiązała Cię z poprzednim pracodawcą.</p>
            <p class="mb-3">Jeżeli miałeś <b>status samozatrudnionego</b>, czyli prowadziłeś/aś własną działalność gospodarczą i świadczyłeś usługi, to możesz ubiegać się kredyt w okresie wypowiedzenia usług. Banki akceptują umowy B2B, jako źródło dochodu, wskazując tylko długość okresu prowadzenia działalności gospodarczej i długość łącznego stażu pracy.</p>
            <p>W przypadku <b>umowy o pracę lub umowy cywilnoprawnej</b>, w okresie wypowiedzenia nie możesz niestety starać się kredyt.</p>`,
  },
  {
    question: 'Jak długo muszę pracować u nowego pracodawcy, żeby móc skorzystać z proponowanych rozwiązań?',
    answer: `<p class="mb-3">Zatrudnienie u nowego pracodawcy na <b>umowie o pracę na czas nieokreślony</b> musi trwać minimum 3 miesiące. Są banki, w których już po 1 miesiącu taka umowa zostanie zaakceptowana, pod warunkiem ciągłości zatrudnienia przez 6 albo 12 miesięcy. Należy wtedy przedstawić świadectwa pracy z poprzedniego miejsca pracy, by móc starać się o kredyt hipoteczny.</p>
            <p class="mb-3">Zatrudnienie u nowego pracodawcy na <b>umowie o pracę na czas określony</b> musi trwać minimum 3 miesiące, a do końca tej umowy musi pozostać minimum 6 miesięcy, by móc starać się o kredyt hipoteczny.</p>
            <p class="mb-3">W przypadku <b>umów B2B</b> banki wymagają minimum 3 miesięcy trwania działalności, a łączny staż pracy musi wynosić minimum 6 miesięcy.</p>
            <p>Dla umów <b>cywilnoprawnych, np. umowy zlecenia</b>, banki wymagają, aby realizacja zleceń trwała minimum 6 miesięcy.</p>`,
  },
];

const LendiOrangeFAQ: FaqElement[] = [
  {
    question: 'Jakie korzyści daje opieka eksperta finansowego?',
    answer: `<p class="mb-3">W ostatnich latach obserwujemy rosnące zapotrzebowanie rynku konsumenckiego na profesjonalne usługi finansowe. Wiąże się to z zawirowaniami na rynku nieruchomościowym i kredytowym, związane z gorszą koniunkturą gospodarczą. Po serii podwyżek stóp procentowych, pogorszeniu się sytuacji wielu kredytobiorców i uruchomieniu programów rządowych, zakładających określone warunki do spełnienia przez beneficjentów, profesjonalna obsługa eksperta finansowego staje się niezbędna w procesie starania się o kredyt.</p>
            <p>Pomoc eksperta nie ogranicza się jednak wyłącznie do wsparcia w rozpoczynającym się procesie kredytowym. W zakres usług eksperta wchodzi również m.in. analiza dotychczasowych umów kredytowych, pomoc w optymalizowaniu zobowiązania, niosąca ze sobą faktyczną zmianę na korzyść w comiesięcznych wydatkach. Ekspert z sukcesem może przeprowadzić restrukturyzację naszego zadłużenia, np. wspierając przy refinansowaniu kredytu, przejściu z dotychczasowego oprocentowania zmiennego, a więc podatnego na wszystkie zmiany wynikające z decyzji NBP, na oprocentowanie okresowo stałe, gwarantujące kilka lat przewidywalności dla portfela. Ekspert pomoże też w konsolidacji zobowiązań i wypracowaniu optymalnej dla możliwości finansowych miesięcznej raty. Konsultacja z ekspertem to także okazja do audytu finansów i sprawdzenia jakie zobowiązanie będzie bezpieczne i najlepiej dopasowane do sytuacji zawodowo-prywatnej.</p>`,
  },
  {
    question: 'W jakich innych kwestiach wsparcie eksperta finansowego będzie dla mnie przydatne?',
    answer: 'Ekspert finansowy to właściwy adres, jeżeli rozważasz np. skorzystanie z jednego z rządowych programów mieszkaniowych. Doskonała znajomość założeń programów, terminów ich wdrożenia oraz praktycznego wykorzystania programów w ofertach banków, pozwala ekspertowi dokonać dokładnej analizy Twojej sytuacji i przygotować zbiór rekomendacji, niezbędnych do spełnienia, aby móc ubiegać się o partycypowanie w programie. Eksperci Lendi dodatkowo mają dostęp do szerokiego katalogu instrumentów ubezpieczeniowych, dzięki którym skutecznie zabezpieczysz interesy swoje i swojej rodziny w wymiarze majątkowym i zdrowotnym.',
  },
  {
    question: 'Czy opieka eksperta finansowego coś mnie kosztuje?',
    answer: 'Opieka eksperta finansowego nie rodzi żadnych kosztów po Twojej stronie. W przypadku korzystania ze wsparcia eksperta przy procesie kredytowym, po jego sfinalizowaniu z sukcesem, prowizję eksperta pokryje z kolei wybrany przez Ciebie bank. Koszt prowizji również nie obciąży w żaden sposób Twojego konta.',
  },
];

const LendiWSRMFAQ: FaqElement[] = [
  {
    question: 'Jakie korzyści daje opieka eksperta finansowego?',
    answer: `<p class="mb-3">W ostatnich latach obserwujemy rosnące zapotrzebowanie rynku konsumenckiego na profesjonalne usługi finansowe. Wiąże się to z zawirowaniami na rynku nieruchomościowym i kredytowym, związane z gorszą koniunkturą gospodarczą. Po serii podwyżek stóp procentowych, pogorszeniu się sytuacji wielu kredytobiorców i uruchomieniu programów rządowych, zakładających określone warunki do spełnienia przez beneficjentów, profesjonalna obsługa eksperta kredytowego staje się niezbędna w procesie starania się o kredyt.</p>
            <p>Pomoc eksperta nie ogranicza się jednak wyłącznie do wsparcia w rozpoczynającym się procesie kredytowym. W zakres usług eksperta wchodzi również m.in. analiza dotychczasowych umów kredytowych, pomoc w optymalizowaniu zobowiązania, niosąca ze sobą faktyczną zmianę na korzyść w comiesięcznych wydatkach. Ekspert z sukcesem może przeprowadzić restrukturyzację naszego zadłużenia, np. wspierając przy refinansowaniu kredytu, przejściu z dotychczasowego oprocentowania zmiennego, a więc podatnego na wszystkie zmiany wynikające z decyzji NBP, na oprocentowanie okresowo stałe, gwarantujące kilka lat przewidywalności dla portfela. Ekspert pomoże też w konsolidacji zobowiązań i wypracowaniu optymalnej dla możliwości finansowych miesięcznej raty. Konsultacja z ekspertem to także okazja do audytu finansów i sprawdzenia jakie zobowiązanie będzie bezpieczne i najlepiej dopasowane do sytuacji zawodowo-prywatnej.</p>`,
  },
  {
    question: 'W jakich innych kwestiach wsparcie eksperta finansowego będzie dla mnie przydatne?',
    answer: 'Ekspert finansowy to właściwy adres, jeżeli rozważasz np. skorzystanie z jednego z rządowych programów mieszkaniowych. Doskonała znajomość założeń programów, terminów ich wdrożenia oraz praktycznego wykorzystania programów w ofertach banków, pozwala ekspertowi dokonać dokładnej analizy Twojej sytuacji i przygotować zbiór rekomendacji, niezbędnych do spełnienia, aby móc ubiegać się o partycypowanie w programie. Eksperci Lendi dodatkowo mają dostęp do szerokiego katalogu instrumentów ubezpieczeniowych, dzięki którym skutecznie zabezpieczysz interesy swoje i swojej rodziny w wymiarze majątkowym i zdrowotnym.',
  },
  {
    question: 'Czy opieka eksperta finansowego coś mnie kosztuje?',
    answer: 'Opieka eksperta kredytowego nie rodzi żadnych kosztów po Twojej stronie. W przypadku korzystania ze wsparcia eksperta przy procesie kredytowym, po jego sfinalizowaniu z sukcesem, prowizję eksperta pokryje z kolei wybrany przez Ciebie bank. Koszt prowizji również nie obciąży w żaden sposób Twojego konta.',
  },
];

const LendiEliteFAQ: FaqElement[] = [
  {
    question: 'Czym jest Lendi Elite?',
    answer: 'Program Lendi Elite to pakiet dodatkowych świadczeń i korzyści do wykorzystania na serwisach Morizon-Gratka, płynących ze współpracy z firmą Lendi',
  },
  {
    question: 'Kto może wziąć udział w programie?',
    answer: 'W programie Lendi Elite mogą wziąć udział przedsiębiorcy, prowadzący działalność deweloperską lub biuro nieruchomości, zaproszeni do programu przez Organizatorów – Grupę Morizon-Gratka lub Lendi.\n' +
      '\n' +
      'Liczba uczestników programu Lendi Elite jest ograniczona.',
  },
  {
    question: 'Co muszę zrobić, aby otrzymać pakiet z budżetem reklamowym od Lendi?',
    answer: `Do otrzymania pakietu świadczeń promocyjnych w serwisach Grupy Morizon-Gratka konieczna jest realizacja określonej liczby kredytów hipotecznych za pośrednictwem Lendi. Dokładny cel liczbowy określa Załącznik 2 Regulaminu.`,
  },
  {
    question: 'W jaki sposób mogę wykorzystać otrzymany pakiet na budżet reklamowy?',
    answer: `Podpisanie umowy o przystąpieniu do programu i osiągnięcie określonych celów w Programie przyznaje prawo do realizacji świadczeń promocyjnych w serwisach Grupy Morizon-Gratka.
              
             Sposób realizacji świadczeń jest uzgadniany każdorazowo w opiekunem klienta po stronie Grupy Morizon-Gratka.`,
  },
  {
    question: 'W jaki sposób mogę zrezygnować z udziału w programie Lendi Elite?',
    answer: `Rezygnacji z udziału w Programie Lendi Elite dokonuje się w drodze pisemnej z miesięcznym okresem wypowiedzenia.`,
  },
  {
    question: 'Co w przypadku, gdy klient kredytowy został pozyskany przez inny serwis niż gratka.pl lub morizon.pl lub serwis partnerski? Czy włącza się on do limitów wymaganych do uzyskania pakietu?',
    answer: `Do limitów wymaganych do uzyskania pakietu wliczane są wszystkie kredyty uruchomione klientom przekazanym przez dewelopera lub biuro nieruchomości. Niezależnie od źródła ich pozyskania.`,
  },

];

const KredytyHipoteczneFAQ: FaqElement[] = [
  {
    question: 'Czym jest zdolność kredytowa?',
    answer: 'Jest to możliwość danej osoby do spłaty zaciągniętego przez nią kredytu. Ocena zdolności kredytowej polega na analizie m.in. historii kredytowej, stanu rodzinnego, dochodów i innych zobowiązań finansowych wnioskodawcy. Pozwala to na określenie maksymalnej kwoty kredytu, jaką będzie w stanie spłacić kredytobiorca. Informacja o przyznanej kwocie będzie zawarta w decyzji kredytowej.',
  },
  {
    question: 'Jaki wkład własny musisz wnieść?',
    answer: 'Banki co do zasady wymagają przy kredycie hipotecznym minimum 20% wkładu własnego. Natomiast jeśli posiadasz nie więcej niż 10% wkładu własnego, bank przedstawi Ci ofertę z podwyższoną marżą stanowiącą ubezpieczenie od mniejszego wkładu własnego.',
  },
  {
    question: 'Jaki jest maksymalny okres spłaty kredytu hipotecznego?',
    answer: 'Kredyty hipoteczne są udzielane na minimum 5 lat, a maksymalnie na 35 lat. Maksymalny okres kredytowania może być też skrócony, jeżeli kredytobiorca jest już w podeszłym wieku.',
  },
  {
    question: 'Jakie oprocentowanie kredytu hipotecznego wybrać?',
    answer: 'Oprocentowanie stałe jest dobrym wyborem, jeśli nie chcesz się przejmować zmianami stopy procentowej i zmianą wysokości raty przez okres obowiązywania stałego oprocentowania kredytu. W przypadku oprocentowania zmiennego każda zmiana stopy procentowej spowoduje zmianę kwoty raty kredytowej. Zatem dokładnie przyjrzyj się, jaka jest aktualna stopa procentowa i jakie są prognozy dotyczące jej zmiany.',
  },
  {
    question: 'Od czego zależy wysokość raty?',
    answer: 'Wysokość raty zależy przede wszystkim od wysokości zaciągniętego kredytu, okresu kredytowania, oraz wysokości rzeczywistego oprocentowania z uwzględnieniem kosztów dodatkowego ubezpieczenia. Kalkulator rat kredytu hipotecznego pozwoli Ci szybko sprawdzić, jak wysokie raty zapłacisz.',
  },
  {
    question: 'Co wpływa na koszt kredytu hipotecznego?',
    answer: 'Na całkowity koszt kredytu wpływają przede wszystkim: marża banku; stawka WIBOR; prowizja za udzielenie kredytu; dodatkowe lub obowiązkowe ubezpieczenie kredytu.',
  },
  {
    question: 'Ile kosztuje kredyt hipoteczny?',
    answer: 'Koszt kredytu hipotecznego zależy od kwoty zobowiązania, ponieważ większość opłat jest wyliczana procentowo od tej sumy. Poza kosztami w banku (prowizja, oprocentowanie), nabywca pokrywa koszty taksy notarialnej, uiszcza opłaty sądowe oraz opłaca podatki.\n',
  },
  {
    question: 'Czym różni się kredyt mieszkaniowy od hipotecznego?',
    answer: 'Kredyt mieszkaniowy i kredyt hipoteczny często są używane zamiennie, ale różnią się zakresem celów, na które można przeznaczyć środki. Kredyt mieszkaniowy jest przeznaczony wyłącznie na cele mieszkaniowe, takie jak zakup lub budowa domu. Kredyt hipoteczny może być użyty na szersze cele, w tym zakup działki, remont nieruchomości, czy spłatę innych zobowiązań. Oba są zabezpieczone hipoteką, wymagają zdolności kredytowej i wkładu własnego.',
  },
  {
    question: 'Czym jest realna stopa oprocentowania kredytu hipotecznego (RRSO)?',
    answer: 'RRSO, czyli Rzeczywista Roczna Stopa Oprocentowania, to wskaźnik pokazujący całkowity koszt kredytu ponoszony przez konsumenta, wyrażony jako procentowa wartość roczna. Obejmuje wszystkie koszty związane z kredytem, takie jak odsetki, prowizje, ubezpieczenia, i inne opłaty. Dzięki RRSO można łatwo porównać oferty kredytowe, gdyż uwzględnia ona wszystkie koszty związane z kredytem, a nie tylko oprocentowanie nominalne.',
  }
];

const EksperciKredytowiFAQ: FaqElement[] = [
  {
    question: 'Czy warto skorzystać z usług eksperta finansowego?',
    answer: 'Jeśli nie masz szczegółowej wiedzy na temat kredytów, warto skorzystać z pomocy eksperta finansowego. Ekspert finansowy przeprowadzi analizę Twoich potrzeb, porówna oferty wielu banków, zaproponuje kredyt najlepiej dopasowany do Twojej aktualnej sytuacji i pomoże w formalnościach.',
  }, {
    question: 'Czy zawsze warto brać kredyt hipoteczny w swoim banku?',
    answer: 'Kredyt hipoteczny w swoim banku nie zawsze będzie najkorzystniejszy. Warto porównać aktualne oferty różnych banków pod względem oprocentowania, prowizji i innych warunków. Możesz skontaktować się z ekspertem finansowym, który pomoże Ci porównać oferty i wybrać najlepszą dla Twoich potrzeb.',
  }, {
    question: 'Ile kosztuje pomoc eksperta finansowego?',
    answer: 'Pomoc eksperta finansowego jest darmowa. Wynagrodzeniem eksperta jest prowizja od banku, w którym uzyskasz kredyt.',
  },
];

const SafeLoanFAQ: FaqElement[] = [
  {
    question: 'Czy warto brać kredyt 2%?',
    answer: 'Jeśli spełniamy warunki uzyskania kredytu, to tak. Szczególnie że pozyskane środki można też wykorzystać na wykończenie albo remont nieruchomości.',
  },
  {
    question: 'Jakie banki udzielają kredytu 2 procent?',
    answer: 'Zgodnie z listą umieszczona na stronie <a href="https://www.bgk.pl/osoby-prywatne/mieszkalnictwo/bezpieczny-kredyt-2/#c28119" rel="nofollow">BGK</a> w sierpniu 2023 r. kredytu 2% udzielały: Alior Bank S.A., Bank BPS i Banki Spółdzielcze Zrzeszenia BPS; Bank PEKAO S.A.; Bank Spółdzielczy Rzemiosła w Krakowie; Bank Spółdzielczy w Brodnicy; Krakowski Bank Spółdzielczy w Krakowie; PKO Bank Polski S.A.; SGB-Bank S.A. i Banki Spółdzielcze Zrzeszone z SGB-Bankiem S.A.; VeloBank S.A.',
  },
  {
    question: 'Od kiedy dostępny jest bezpieczny kredyt 2%?',
    answer: 'Kredyt dostępny jest od lipca 2023 r.',
  },
];

const LoanForStartFAQ: FaqElement[] = [
  {
    question: 'Kiedy rusza program "Kredyt na Start"?',
    answer: 'Ministerstwo Rozwoju i Technologii na stronach rządowych zapowiedziało, że program Kredyt na Start ma zostać uruchomiony od 15 stycznia 2025 roku.',
  },
  {
    question: 'Jakie są warunki otrzymania kredytu w ramach programu "Kredyt na start"?',
    answer: 'Kredyt będzie mógł otrzymać zarówno singiel, jak i gospodarstwo wieloosobowe. Istnieje kryterium dochodowe, jednak przekroczenie określonych dochodów nie wyklucza z udziału w programie, a jedynie proporcjonalnie zmniejsza kwotę kredytu objętą preferencyjnym oprocentowaniem.',
  },
  {
    question: 'Jaka jest maksymalna kwota kredytu w ramach programu "Kredyt na start"?',
    answer: 'Kredytobiorca będzie mógł otrzymać taką kwotę kredytu, na jaką pozwoli jego aktualna zdolność kredytowa. Preferencyjne oprocentowanie będzie natomiast dotyczyć tylko tej części kredytu, której kwota mieści się w ustalonych limitach.',
  },
  {
    question: 'Ile trzeba zarabiać żeby dostać Kredyt na Start?',
    answer: `Zasadniczo mogłoby się wydawać, że im większe dochody, tym korzystniej. Jednak projekt #naStart jasno określa, że dochody Twojego gospodarstwa domowego nie mogą przekroczyć określonych limitów.
             <p class="mt-4">Przekroczenie limitu dochodu skutkuje obniżeniem dopłaty w Kredycie na Start:</p>
              <ul class="pl-6">
                  <li>w przypadku singla 50% przekroczonej kwoty (np. singiel zarabiający 8000zł przekracza limit o 1000zł, więc dopłata zostanie obniżona o 500zł).</li>
                  <li>w pozostałych przypadkach o 25% przekroczonej kwoty np. para zarabiająca 13000zł przekracza limit o 1000zł, więc dopłata zostanie obniżona o 250zł.</li>
              </ul>`,
  },
  {
    question: 'Jak złożyć wniosek o Kredyt na Start?',
    answer: `Ponieważ nabór wniosków rozpocznie się dopiero za kilka miesięcy, szczegółowe informacje na temat procedury wnioskowania są obecnie niedostępne. Możemy jednak przypuszczać, że banki będą przyjmować wnioski o kredyt z programu Kredyt na Start 
              w sposób zbliżony do procedury składania wniosków o hipoteki na standardowych warunkach.<p class="mt-4">Ze względu na złożoność programu Kredyt na Start i jego nowe regulaminy, <span class="headline-5">zdecydowanie warto rozważyć skorzystanie 
              z pomocy eksperta finansowego Lendi.</span> Ekspert Lendi posiada szeroką wiedzę na temat 
              programu i ma dostęp do ofert wszystkich wiodących banków. Dzięki temu <span class="headline-5">może pomóc Ci wybrać najkorzystniejszą ofertę Kredytu na Start 
              dopasowaną do Twoich potrzeb i sytuacji finansowej.</span></p>`,
  },
  {
    question: 'Jaki jest limit wieku dotyczący Kredytu na start?',
    answer: `W programie Mieszkanie na Start limity wieku mają zastosowanie jedynie do jednej grupy beneficjentów, ponieważ z programu mogą skorzystać:
              <ul class="pl-6">
                  <li>single w wieku do 35 lat,</li>
                  <li>gospodarstwa domowe składające się z co najmniej 2 osób, bez ograniczenia wiekowego. Mogą to być małżeństwa, pary żyjące w nieformalnych związkach (o ile będą współwłaścicielami mieszkania), z dziećmi lub bez.</li>
              </ul>
               <p class="mt-4">Natomiast ograniczenie wieku nie ma zastosowania do kredytów o oprocentowaniu 0% dla rodzin wielodzietnych, wychowujących co najmniej trójkę dzieci</p>`,
  },
];

export const AdditionalCreditabilityData: ExpansionPanelElement[] = [
  {
    title: 'Na jaką kwotę brać kredyt hipoteczny?',
    text: `Gdy starasz się o kredyt hipoteczny, postaraj się wykorzystać nie więcej niż 80% swojej zdolności kredytowej. Dzięki temu Twoja rata kredytowa będzie niższa, a kwotę zaoszczędzoną dzięki płaceniu niższych odsetek można przeznaczyć na nadpłacenie kredytu hipotecznego.`,
  },
  {
    title: 'W czym pomoże kalkulator zdolności kredytowej?',
    text: `Kalkulator zdolności kredytowej to narzędzie, które pomoże Ci obliczyć Twoją zdolność kredytową. Wprowadzając kilka podstawowych informacji, zobaczysz szacunkową kwotę kredytu hipotecznego, jaką banki będą skłonne Ci udzielić. Dzięki temu będziesz wiedział, na jaką nieruchomość Cię stać – przytulne mieszkanie, dom na przedmieściach, czy ogromny apartament.`,
  },
  {
    title: 'Jaki wkład własny trzeba posiadać?',
    text: 'Warto posiadać wkład własny w wysokości co najmniej 20% wartości nieruchomości. Dzięki temu nie poniesiesz dodatkowych kosztów ubezpieczenia niskiego wkładu własnego. Co więcej, kwota zaciągniętego kredytu hipotecznego będzie niższa, więc będziesz mógł płacić niższą ratę miesięczną lub zaciągnąć kredyt hipoteczny na krótszy okres.',
  },
  {
    title: 'Gdzie znaleźć oferty kredytów hipotecznych?',
    text: 'Po obliczeniu zdolności kredytowej, kalkulator porównuje dostępne kredyty hipoteczne w różnych bankach, pokazuje wysokość raty kredytowej, łączny koszt odsetek, oprocentowanie i RRSO. Najtańsza oferta kredytu hipotecznego niekoniecznie będzie najlepsza w Twojej konkretnej sytuacji finansowej, dlatego warto skonsultować się z ekspertem finansowym który przeprowadzi analizę Twoich potrzeb i zaproponuje kredyt hipoteczny najlepszy dla Ciebie.',
  },
];

export const ZdolnoscKredytowaFAQ: FaqElement[] = [
  {
    question: 'Jak poprawić zdolność kredytową?',
    answer: `
    1. Płać wszystkie swoje zobowiązania na czas.<br/>
    2. Nie zaciągaj zbyt wielu kredytów jednocześnie.<br/>
    3. Zwiększ swoje dochody.<br/>
    4. Zmień formę zatrudnienia na taką, która jest preferowana przez banki.<br/>
    5. Staraj się unikać niepotrzebnych wydatków.<br/>`,
  }, {
    question: 'Czym są stopy procentowe?',
    answer: 'Stopy procentowe to wskaźnik określający wysokość oprocentowania kredytów, a więc w konsekwencji to od stóp procentowych zależy koszt kredytu. Stopy procentowe są ustalane przez bank centralny i mogą się zmieniać w zależności od aktualnej sytuacji gospodarczej w danym kraju.',
  },
  {
    question: 'W jakich ratach mogę spłacać kredyt hipoteczny?',
    answer: 'Spłatę kredytu hipotecznego <b>można dostosować do indywidualnych preferencji i sytuacji finansowej poprzez różne rodzaje rat</b>, takie jak raty stałe czy malejące. Ważne jest zrozumienie różnic między nimi i wybór najlepszego rozwiązania.'
  },
  {
    question: 'Ile trzeba czekać na decyzję kredytową banku?',
    answer: 'Decyzja banku w sprawie kredytu hipotecznego <b>może zająć od 2 do 3 tygodni</b>, a jej rezultat często zależy od oceny zdolności kredytowej klienta.'
  },
  {
    question: 'Co może być wkładem własnym przy kredycie hipotecznym?',
    answer: 'Wkład własny przy kredycie hipotecznym może pochodzić z różnych źródeł, w tym <b>gotówki, innych nieruchomości, pożyczki z zakładu pracy, środków zgromadzonych na IKE/IKZE, książeczki mieszkaniowej oraz zadatku</b> i innych poniesionych wcześniej wydatków, ale wymaga odpowiedniej dokumentacji.'
  },
  {
    question: 'Znam swoją zdolność kredytową – co dalej?',
    answer: 'Kalkulator zdolności kredytowej to proste narzędzie, które pomoże Ci ocenić możliwości zaciągnięcia kredytu hipotecznego. Po uzyskaniu wyniku kalkulacji możesz:<br>' +
      '1. Skorzystać z prezentowanych ofert kredytowych, wybierając najkorzystniejszą pod względem finansowym.<br>' +
      '2. Kliknąć "Policz zdolność" przy wybranej ofercie, aby skontaktować się z ekspertem finansowym.<br>' +
      '3. Umówić się na spotkanie z ekspertem finansowym, który wyliczy zdolność kredytową i przedstawi spersonalizowaną ofertę.<br>' +
      '4. Złożyć wniosek kredytowy w banku i oczekiwać na decyzję.'
  }
];
export const RefinanceFAQ: FaqElement[] = [
  {
    question: 'Po co korzystać z kalkulatora zmiany oprocentowania kredytu hipotecznego?',
    answer: `Kalkulator zmiany oprocentowania kredytu hipotecznego obliczy, jak zmieni się wysokość twojej miesięcznej raty po zmianie oprocentowania kredytu. Równocześnie zobaczysz ile możesz zaoszczędzić dzięki zamianie swojego aktualnego kredytu hipotecznego na lepszą ofertę.`,
  }, {
    question: 'Czy warto zmieniać kredyt hipoteczny?',
    answer: 'Zawsze warto sprawdzić, czy aktualnie dostępna jest bardziej korzystna oferta kredytu hipotecznego. Dzięki zmianie oferty kredytu hipotecznego możesz zaoszczędzić sporą sumę pieniędzy.',
  }, {
    question: 'Gdzie znaleźć najlepszą ofertę kredytu hipotecznego?',
    answer: 'Możesz samodzielnie sprawdzić i porównać oferty kredytów hipotecznych wszystkich banków, lub skorzystać z pomocy eksperta finansowego który nie tylko porówna dla Ciebie wszystkie oferty, ale także przeanalizuje Twoją aktualną sytuację finansową i zaproponuje najlepszą ofertę dopasowaną do Twoich wymagań.',
  },
];

export const aboutUsFaq: FaqElement[] = [
  {
    question: 'Jakie produkty oferujemy?',
    answer: 'W portfolio produktowym Lendi znajdują się aktualizowane na bieżąco oferty kredytowe wszystkich największych banków w Polsce. Proponujemy naszym klientom rozwiązania finansowe z obszarów: <br>' +
      '• Kredytów hipotecznych <br>' +
      '• Kredytów gotówkowych <br>' +
      '• Produktów finansowych dla firm. <br>' +
      'Jednocześnie, indywidualnie dobieramy zarówno narzędzie finansowe, jak i warunki oferty do potrzeb i możliwości klienta. Każdy proces zaczynamy więc przede wszystkim od kompleksowej i dokładnej analizy potrzeb. \n'
  },
  {
    question: 'Jak długo istniejemy na rynku?',
    answer: 'Firma Lendi, jako pośrednik kredytowy, istnieje na rynku od 2016 roku, natomiast jej korzenie sięgają o wiele dalej. <br>' +
      'Lendi powstało jako przedłużenie FinPack – dostawcy pierwszego na rynku oprogramowania, usprawniającego i automatyzującego pracę ekspertów kredytowych. Po kilku latach dopracowywania portfolio technologicznego, FinPack rozszerzył swoją ofertę o pośrednictwo kredytowe i przekształcił się w Lendi – pełnowymiarowego brokera kredytowego z silnym zapleczem technologicznym.' +
      'Od 2016 roku realizujemy usługi pośrednictwa dla klientów indywidualnych i firm, a jednocześnie wciąż dostarczamy najnowocześniejszych narzędzi online do sprawnego zbadania zdolności kredytowej, porównania aktualnych ofert i rozwijania wiedzy na temat rynku finansowego.'
  },
  {
    question: 'Jakie są nasze główne cele i wartości?',
    answer: 'Podstawowym i najważniejszym celem Lendi, od jego powstania, było i jest dostarczanie wyczerpującej i kompletnej wiedzy na temat finansów, szczególnie w kontekście produktów finansowych, która jest niezbędna do podjęcia świadomej decyzji o wyborze najlepiej dopasowanego kredytu.\n' +
      '<br>' +
      'Sfinansowanie własnej nieruchomości – domu lub mieszkania – to jedna z najważniejszych decyzji w życiu, rzutująca na wszystkie jego obszary w długim interwale czasowym. Dlatego konieczne jest, aby móc podejmować ją w komforcie wiedzy o prawach rządzących rynkiem finansowym. Nasze narzędzia, połączone z wiedzą i doświadczeniem ekspertów finansowych, mają za zadanie ułatwić tę decyzję i zwiększyć poczucie bezpieczeństwa.' +
      '<br>' +
      'Jednocześnie docelowo pragniemy dostarczać kompleksowej usługi na każdym etapie podejmowania decyzji o zakupie własnego domu. Już od momentu wyboru właściwej nieruchomości, przez jej sprawne i optymalne finansowanie, aż po zabezpieczenia mienia, zdrowia i życia kredytobiorcy i jego bliskich. Dlatego konsekwentnie rozwijamy się we wszystkich wskazanych kierunkach – nieruchomościowym, kredytowym oraz ubezpieczeniowym, cały czas proponując naszym klientom coraz szerszy wachlarz usług.'
  },
  {
    question: 'Czy przekazywanie moich danych do porównywarki jest bezpieczne?',
    answer: 'Jako pośrednik kredytowy podlegamy bardzo ścisłej regulacji prawnej i nasza działalność jest regularnie kontrolowana przez organy państwa. Działamy zgodnie z przepisami prawa, chroniąc zarówno dane wrażliwe naszych klientów, jak i dochowując tajemnicy bankowej. Narzędzia, które udostępniamy klientom spełniają szczegółowe wytyczne, wynikające m.in. z przepisów RODO, ustawy o kredycie hipotecznym, jak i innych aktów prawnych, do których przestrzegania jesteśmy zobligowani.'
  }
];

export const expertsFAQ: FaqElement[] = [
  {
    question: 'Kim jest pośrednik kredytowy i czym się zajmuje?',
    answer: 'Działalność pośrednika kredytu hipotecznego jest działalnością regulowaną - aby ją wykonywać podmiot musi spełnić szereg wymagań, uzyskać zezwolenie wydawane przez Komisję Nadzoru Finansowego oraz zostać wpisany na rejestr pośredników kredytu hipotecznego prowadzoną przez KNF. Współpracuje z wieloma bankami, dzięki czemu może przedstawić różne oferty i pomóc w załatwieniu formalności związanych z wnioskiem kredytowym. Lendi jest pośrednikiem kredytu hipotecznego, a jego eksperci działają w jego imieniu jako agenci lub personel.'
  },
  {
    question: 'Czy korzystanie z usług pośrednika kredytowego jest płatne?',
    answer: 'Wynagrodzenie pośrednika kredytowego pochodzi od banku, więc Ty jako klient nie ponosisz dodatkowych kosztów. Usługi pośrednika są dla Ciebie bezpłatne, a korzystanie z jego pomocy nie zwiększa kosztów Twojego kredytu. Natomiast jeśli  zdecydujesz się na współpracę z niezależnym doradcą kredytowym, który pobiera opłatę od klienta poinformuje Cię on o tym fakcie przed rozpoczęciem świadczenia usług.'
  },
  {
    question: 'Jakie są zalety korzystania z usług pośrednika kredytowego?',
    answer: 'Pośrednik kredytowy współpracuje z wieloma bankami, przez co przygotowując zestawienie kilku ofert kredytowych pozwoli zaoszczędzić Twój czas i wesprze Cię w wypełnianiu dokumentów niezbędnych w procesie kredytowym.'
  },
  {
    question: 'Czy pośrednik kredytowy działa tylko stacjonarnie?',
    answer: 'Pośrednik kredytowy oferuje swoje usługi zarówno stacjonarnie, jak i online. Możesz skorzystać z jego pomocy bez wychodzenia z domu, poprzez telefon, chat lub wideorozmowę, co jest wygodne, szczególnie jeśli masz ograniczony czas na spotkania osobiste.'
  },
  {
    question: 'Jakie dokumenty są potrzebne podczas współpracy z pośrednikiem kredytowym?',
    answer: 'Do oceny zdolności kredytowej pośrednik będzie potrzebował dokumentów potwierdzających Twoje dochody, np. wyciągów z konta bankowego, a także PIT-ów z poprzedniego roku. Jeśli wybrałeś już nieruchomość, konieczne będą również dokumenty dotyczące tej nieruchomości.'
  }
]

export function useFAQ() {
  const createFaqSchema = (faq: FaqElement[]): JsonLD => ({
    '@type': 'FAQPage',
    mainEntity: faq.map((faqItem) => ({
      '@type': 'Question',
      name: faqItem.question,
      acceptedAnswer: [
        {
          '@type': 'Answer',
          text: faqItem.answer,
        },
      ],
    })),
    "@id": "https://www.lendi.pl/#/schema/FAQPage/1"
  });

  return {
    createFaqSchema,
    LendiCorpFAQ,
    LendiRaspFAQ,
    LendiEliteFAQ,
    KredytyHipoteczneFAQ,
    EksperciKredytowiFAQ,
    SafeLoanFAQ,
    LoanForStartFAQ,
    LendiOrangeFAQ,
    LendiWSRMFAQ,
    ZdolnoscKredytowaFAQ,
    aboutUsFaq,
    expertsFAQ,
  };
}
